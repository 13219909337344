import React, { useState } from "react";
import TeacherResumeBuilder from "./TeacherResumeBuilder";
import { Result, Typography } from "antd";

const headerContainer = {
  background: "linear-gradient(to top right, #450b7c, #563cc9)",
};
const container = {
  width: "700px",
  padding: "20px",
  margin: "0 auto",
};
function Career() {
  const [built, setBuilt] = useState(false);

  return (
    <>
      <header style={headerContainer}>
        <div style={container}>
          <Typography.Title level={3} style={{ color: "#ffffff" }}>
            BrightLearning
          </Typography.Title>
          <Typography.Title
            level={2}
            style={{ color: "#fff", textAlign: "center" }}>
            Application for teacher position
          </Typography.Title>
        </div>
      </header>
      {built ? (
        <Result
          status="success"
          title="Your resume has been sent successfully"
          subTitle="Our HR Team will process your resume and contact you back soon!"
        />
      ) : (
        <TeacherResumeBuilder onComplete={() => setBuilt(true)} />
      )}
    </>
  );
}

export default Career;
