import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Button,
  Space,
  Row,
  Col,
  DatePicker,
  notification,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";

const options = [
  { label: "Class 6 to 8", value: "Class 6 to 8" },
  { label: "Class 9 and 10", value: "Class 9 and 10" },
  { label: "Class 11 and 12", value: "Class 11 and 12" },
  { label: "NEET", value: "NEET" },
  { label: "JEE", value: "JEE" },
];

const gadgets = [
  { label: "Desktop computer", value: "Desktop computer" },
  { label: "Laptop", value: "Laptop" },
  { label: "Digital writing pad", value: "Digital writing pad" },
  { label: "Headphone and Mic", value: "Headphone and Mic" },
];

const teaching_subject = [
  { label: "Mathematics", value: "Mathematics" },
  { label: "Physics", value: "Physics" },
  { label: "Chemistry", value: "Chemistry" },
  { label: "Biology", value: "Biology" },
  { label: "Botany", value: "Botany" },
  { label: "Zoology", value: "Zoology" },
  { label: "Science", value: "Science" },
  { label: "Commerce", value: "Commerce" },
  { label: "Accountancy", value: "Accountancy" },
];

function Career({ onComplete }) {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);
    const dob = moment(values.dob).format("YYYY-MM-DD");
    const url = `${process.env.REACT_APP_API_BASE}/public/career/teacher`;
    axios
      .post(url, { ...values, dob })
      .then((response) => {
        onComplete();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response?.data)
          notification.error({
            message: error.response.data.message,
            placement: "bottomRight",
          });
      })
      .then(() => {
        form.resetFields();
      });
  };

  return (
    <div
      style={{ width: "700px", margin: "0 auto", padding: "40px 30px" }}
      className="noselect">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div style={{ paddingBottom: "30px" }}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Full Name"
                name="name"
                rules={[
                  { required: true, message: "Pleade enter Name" },
                  { min: 3, message: "Name should be minimum of 3 characters" },
                ]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter Email" },
                  { type: "email", message: "Invalid email address" },
                ]}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mobile"
                name="mobile"
                rules={[
                  { required: true, message: "Please enter Mobile number" },
                  { pattern: /^[6-9]\d{9}$/, message: "Invalid mobile number" },
                ]}>
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="dob"
                label="Date of birth"
                rules={[
                  { required: true, message: "Please enter date of birth" },
                ]}>
                <DatePicker format="DD-MMM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please select gender" }]}>
                <Select placeholder="Select Gender">
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value="Female">Female</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Pincode of residence"
                name="pincode"
                rules={[
                  { required: true, message: "Please enter pincode" },
                  { pattern: /^[1-9]\d{5}$/, message: "Invalid pincode" },
                ]}>
                <Input placeholder="Pincode" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item label="Educational Qualification">
          <Form.List
            name="qualification"
            rules={[
              {
                validator: async (_, qualification) => {
                  if (!qualification || qualification.length < 1) {
                    return Promise.reject(
                      new Error("Please add at least one degree")
                    );
                  }
                },
              },
            ]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "degree"]}
                      fieldKey={[fieldKey, "degree"]}
                      rules={[
                        { required: true, message: "Please enter degree" },
                      ]}>
                      <Input placeholder="Degree" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "subject"]}
                      fieldKey={[fieldKey, "subject"]}
                      rules={[
                        { required: true, message: "Please enter subject" },
                      ]}>
                      <Input placeholder="Subject" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "institution"]}
                      fieldKey={[fieldKey, "institution"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter institution",
                        },
                      ]}>
                      <Input placeholder="Instituion" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "passed"]}
                      fieldKey={[fieldKey, "passed"]}
                      rules={[
                        {
                          required: true,
                          message: "Enter Year of passing",
                        },
                      ]}>
                      <InputNumber placeholder="Year of passing" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add Qualification
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Additional qualification if any (eg. SET / NET)">
          <Form.List name="additional_qualification">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 15,
                    }}>
                    <Form.Item
                      style={{
                        flexGrow: 1,
                        marginRight: "8px",
                      }}
                      {...restField}
                      name={[name, "qualification"]}
                      fieldKey={[fieldKey, "qualification"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Qualification",
                        },
                      ]}>
                      <Input placeholder="Qualification" />
                    </Form.Item>
                    <Form.Item
                      style={{
                        flexGrow: 1,
                        marginRight: "8px",
                      }}
                      {...restField}
                      name={[name, "passed"]}
                      fieldKey={[fieldKey, "passed"]}
                      rules={[
                        { required: true, message: "Please enter Year" },
                      ]}>
                      <InputNumber placeholder="Year" />
                    </Form.Item>
                    <div style={{ paddingTop: 5 }}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add Additional Qualification
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Offline Teaching experience">
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="offline_exp"
                    initialValue={0}
                    rules={[{ required: true, message: "Enter experience" }]}>
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="offline_exp_unit" initialValue="Years">
                    <Select>
                      <Select.Option value="Years">Years</Select.Option>
                      <Select.Option value="Months">Months</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Online Teaching experience">
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="online_exp"
                    initialValue={0}
                    rules={[{ required: true, message: "Enter experience" }]}>
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="online_exp_unit" initialValue="Years">
                    <Select>
                      <Select.Option value="Years">Years</Select.Option>
                      <Select.Option value="Months">Months</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="I am comfortable in teaching"
          name="interest"
          rules={[{ required: true, message: "Please select atleast one" }]}>
          <Checkbox.Group options={options} />
        </Form.Item>

        <Form.Item
          label="Subjects I can teach"
          name="teaching_subject"
          rules={[{ required: true, message: "Please select atleast one" }]}>
          <Checkbox.Group>
            <Row gutter={16}>
              {teaching_subject.map((i) => (
                <Col span={8} key={i.value}>
                  <Checkbox value={i.value}>{i.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="I have following gadgets for teaching"
          name="gadgets"
          rules={[{ required: true, message: "Please select gadgets" }]}>
          <Checkbox.Group options={gadgets} />
        </Form.Item>

        <Form.Item label="Languages Known">
          <Form.List
            name="languages"
            rules={[
              {
                validator: async (_, languages) => {
                  if (!languages || languages.length < 1) {
                    return Promise.reject(
                      new Error("Please enter atleast one")
                    );
                  }
                },
              },
            ]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "language"]}
                      fieldKey={[fieldKey, "language"]}
                      rules={[
                        { required: true, message: "Please enter language" },
                      ]}>
                      <Input placeholder="Language" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add language
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button htmlType="submit" type="primary" block loading={isSubmitting}>
            Submit my Application
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Career;
